import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import data from "./Data.json";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Blotter = () => {
  document.body.style.background = "#efefef";
  const [startDate, setStartDate] = useState(new Date());

  function renderSwitch({ ...props }) {
    switch (props.Name) {
      case "BOND":
        return props.TableData.map((data, index) => (
          <tr key={index}>
            <td>{data.id}</td>
            {/* <td>{data.date}</td> */}
            <td>{data.book}</td>
            <td>{data.party}</td>
            <td>{data.other}</td>
            <td>{data.type}</td>
            <td>{data.denom}</td>
            <td>{data.coupon}</td>
            <td>{data.notional}</td>
            <td>{data.buysell}</td>
            <td>{data.matdate}</td>
            <td>{data.price}</td>
            <td>{data.yield}</td>
          </tr>
        ));
      default:
        return props.TableData.map((data, index) => (
          <tr key={index}>
            <td>{data.id}</td>
            {/* <td>{data.date}</td> */}
            <td>{data.book}</td>
            <td>{data.party}</td>
            <td>{data.other}</td>
            <td>{data.type}</td>
            <td>{data.buysell}</td>
            <td>{data.quantity}</td>
            <td>{data.matdate}</td>
            <td>{data.price}</td>
          </tr>
        ));
    }
  }

  const MyTable = ({ ...props }) => {
    return (
      <>
        <Card>
          <Card.Header>{props.Name}</Card.Header>
          <Table responsive>
            <thead>
              <tr>
                {props.Header.map((data, index) => (
                  <th key={index}>{data}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderSwitch(props)}</tbody>
          </Table>
        </Card>
      </>
    );
  };

  return (
    <>
      <div className="container-fuild">
        <div className="row m-0 p-0 mt-4 mb-4">
        <div className="col-sm-12 text-right mb-3 pr-5">
            <DatePicker
              className="date-custom"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              popperPlacement="top-start'"
            />
          </div>
          <div className="col-sm-6 pl-2 pr-2">
            <MyTable
              Name="BOND"
              Header={data.Bond.Header}
              TableData={data.Bond.Data}
            />
          </div>
          <div className="col-sm-6 pl-2 pr-2">
            <MyTable
              Name="EQUITY"
              Header={data.Equity.Header}
              TableData={data.Equity.Data}
            />
          </div>
        </div>
        <div className="row p-0 m-0 mb-4">
          <div className="col-sm-4 pl-2 pr-2">
            <MyTable
              Name="FX"
              Header={data.Fx.Header}
              TableData={data.Fx.Data}
            />
          </div>
          <div className="col-sm-4 pl-2 pr-2">
            <MyTable
              Name="FUTURES"
              Header={data.Futures.Header}
              TableData={data.Futures.Data}
            />
          </div>
          <div className="col-sm-4 pl-2 pr-2">
            <MyTable
              Name="COMMODITIES"
              Header={data.Commodities.Header}
              TableData={data.Commodities.Data}
            />
          </div>
        </div>

        {/* <iframe
          width="100%"
          height="780vh"
          scrolling="no"
          frameBorder="0"
          src="https://airwealth-backend.digitalairtech.net/Blotter/Index"
        ></iframe> */}
      </div>
    </>
  );
};

export default Blotter;
