import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "../../actions/auth.actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Login.scss";

const Login = (props) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "eve.holt@reqres.in",
      password: "1223456",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("The Username/Email field is required."),
      password: Yup.string()
        .min(6, "Must be 6 characters or more")
        .required("The Password field is required."),
    }),
    onSubmit: (e) => {
      dispatch(authActions.login(e.email, e.password, props));
    },
  });
  useEffect(() => {
    dispatch(authActions.logout());
  });

  return (
    <div className="login-banner d-flex align-items-center">
      <div className="inner-banner w-100">
        <div className="row no-gutters w-100">
          <div className="col-md-6 col-sm-6 col-lg-6 d-flex justify-content-center">
            <div className="login-logo"></div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 d-flex justify-content-center">
            <form
              className="p-5 cust-form"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <h3>Sign in</h3>
              <div className="form-group mt-4">
                <input
                  name="email"
                  type="text"
                  placeholder="Email or Phone"
                  autoFocus=""
                  autoComplete="off"
                  className="form-control border-0 px-0 cust-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["email"]}
                  isinvalid={formik.touched["email"] && formik.errors["email"]}
                />
                {formik.touched["email"] && formik.errors["email"] ? (
                  <span className="text-danger field-validation-valid">
                    {formik.errors["email"]}
                  </span>
                ) : null}
              </div>
              <div className="form-group">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  required=""
                  autoComplete="off"
                  className="form-control border-0 px-0 cust-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["password"]}
                  isinvalid={
                    formik.touched["password"] && formik.errors["password"]
                  }
                />
                {formik.touched["password"] && formik.errors["password"] ? (
                  <span className="text-danger field-validation-valid">
                    {formik.errors["password"]}
                  </span>
                ) : null}
              </div>
              <div className="row py-3">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xs-6 w-50">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label cust-span custSize"
                      htmlFor="exampleCheck1"
                    >
                      &nbsp;Remember me
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xs-6 w-50 custSize">
                  <a href="#">
                    <u className="float-right">Forgot Password?</u>
                  </a>
                </div>
              </div>
              <button
                type="submit"
                className="btn cust-btn text-uppercase text-white w-100 py-2 mt-3"
              >
                login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
