import { constants } from '../store/types'
import AuthService from '../services/auth'

const login = (email, password, props) => {
  return dispatch => {
    AuthService.login(email, password).then(
      user => {
        dispatch({ type: constants.LOGIN_SUCCESS, user })
        props.history.push('/index/Dashboard')
      },
      error => {
        dispatch({ type: constants.LOGIN_FAILURE, error })
      }
    )
  }
}

const logout = () => {
  AuthService.logout()
  return { type: constants.LOGOUT }
}

export const authActions = {
  login,
  logout
}
