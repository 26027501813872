import React from "react";
import "./Dashboard.scss";

const Dashboard = () => {
document.body.style.background = '#efefef'
  return (
    <>
      <div className="container-fuild">
      <iframe width="100%" height="3550vh" scrolling="no" frameBorder="0" src="http://airwealth-backend.digitalairtech.net/Dashboard/Index" ></iframe>
      </div>
    </>
  );
};

export default Dashboard;
