import React from "react";

const Footer = () => {
  return (
    <>
      <div className="Footer-bg">
        <div className="row no-gutters px-5">
          <div className="col-md-4 col-xs-4 col-sm-4 d-flex align-items-center">
            <div className="FooterLogo Footer-logo"></div>
          </div>
          <div className="col-md-4 col-xs-4 col-sm-4">
            <nav className="nav d-flex justify-content-center" id="footer-nav">
              <a className="nav-link active" href="#">
                <div className="socialIcons" id="fb"></div>
              </a>
              <a className="nav-link" href="#">
                <div className="socialIcons" id="tw"></div>
              </a>
              <a className="nav-link" href="#">
                <div className="socialIcons" id="in"></div>
              </a>
              <a className="nav-link" href="#">
                <div className="socialIcons" id="insta"></div>
              </a>
            </nav>
          </div>
          <div className="col-md-4 col-xs-4 col-sm-4 d-flex align-items-center">
            <div className="FooterLogo-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
