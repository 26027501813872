import React from "react";

const Footer = () => {
  return (
    <>
      <div className="FooterBG">
        <div className="px-5 py-2"> 
          <div className = "d-flex justify-content-between">
           <div className="FooterLogo mb-3"></div>
           <div className="FooterDigitalLogo mb-3"></div>
          </div>
          <div className="row py-2 parentFooterLogo">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-3 my-2 px-0">
                  <span className="text-uppercase FooterSpan">products</span>
                  <div className="list-group pt-2">
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Home
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Platform
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Solution
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Contract
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      <u>View all</u>
                    </a>
                  </div>
                </div>
                <div className="col-md-3 my-2 px-0">
                  <span className="text-uppercase FooterSpan">support</span>
                  <div className="list-group pt-2">
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Help Center
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Community Forums
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Enterprise Support
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Download and Install
                    </a>
                  </div>
                </div>
                <div className="col-md-3 my-2 px-0">
                  <span className="text-uppercase FooterSpan">
                    blogs and community
                  </span>
                  <div className="list-group pt-2">
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      AirWealth Blog
                    </a>
                    <div className="row mt-4">
                      <div className="col-md-3 col-sm-3 col-xs-3 pl-2">
                        <a href="">
                          <div className="FooterIcon" id="FooterIcon_1"></div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-3 col-xs-3  pl-2">
                        <a href="">
                          <div className="FooterIcon" id="FooterIcon_2"></div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-3 col-xs-3  pl-2">
                        <a href="">
                          <div className="FooterIcon" id="FooterIcon_3"></div>
                        </a>
                      </div>
                      <div className="col-md-3 col-sm-3 col-xs-3  pl-2">
                        <a href="">
                          <div className="FooterIcon" id="FooterIcon_4"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 my-2 px-0">
                  <span className="text-uppercase FooterSpan">AirWealth</span>
                  <div className="list-group pt-2">
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      About
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Newspaper
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Careers
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action FooterLi"
                    >
                      Events
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-right px-0 pt-3">
              <label htmlFor="basic-url" className="FooterSpan text-uppercase">
                sign up for newsletter and updates
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control rounded-0 FooterInput"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button
                    className="input-group-text rounded-0 text-uppercase FooterBtn"
                    id="basic-addon2"
                  >
                    subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-uppercase py-2 ">
          <p className="text-white Footer_child_pr">
            &#169; 2018 digital air all rights reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
