import React, { useEffect, useState, useRef } from "react";
import { Card, Table } from "react-bootstrap";
import "./IndictiveMarket.scss";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const IndictiveMarket = () => {
  document.body.style.background = "#efefef";
  const ws = useRef(null);
  const [stockData, setstockData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [depositsData, setdepositsData] = useState([]);
  const [depositstwoData, setdepositstwoData] = useState([]);
  const [futuresData, setfuturesData] = useState([]);
  const [swapratesData, setswapratesData] = useState([]);
  const [equityData, setequityData] = useState([]);
  const [fxData, setfxData] = useState([]);
  const [commoditiesData, setcommoditiesData] = useState([]);
  let singlestock = [];

  useEffect(() => {
    ws.current = new WebSocket("wss://finance-demo-api.digitalairtech.net/ws");
    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ RequestType: "StockBucket" }));
    };
    ws.current.onclose = () => console.log("ws closed");

    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    if (!ws.current) return;
    ws.current.onmessage = (e) => {
      var messages = JSON.parse(e.data);
      switch (messages.RequestType) {
        case "StockBucket":
          let index = singlestock.findIndex(
            (vendor) => vendor["key"] === messages.Data.symbol
          );
          if (index == -1) {
            singlestock.push({
              key: messages.Data.symbol,
              value: messages.Data,
            });
          } else {
            singlestock[index] = {
              key: messages.Data.symbol,
              value: messages.Data,
            };
          }
          setstockData([...singlestock]);
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    setdepositsData([
      {
        startdate: "3/5/2021",
        enddate: "1b",
        rate: "5.3000%",
        basis: "Act/360",
        prevclose: "%",
        chg: "%",
      },
      {
        startdate: "3/8/2021",
        enddate: "1b",
        rate: "5.3000%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "1b",
        rate: "5.3000%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "1W",
        rate: "5.3325%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "2W",
        rate: "5.3600%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "1M",
        rate: "5.3950%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "2M",
        rate: "5.4315%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
      {
        startdate: "3/9/2021",
        enddate: "3M",
        rate: "5.4580%",
        basis: "Act/360",
        prevclose: "",
        chg: "",
      },
    ]);
  }, []);
  useEffect(() => {
    setdepositstwoData([
      {
        name: "FF",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
      {
        name: "1mL",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
      {
        name: "3mL",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
      {
        name: "6mL",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
      {
        name: "12mL",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
      {
        name: "SOFR",
        rate: "",
        prevclose: "",
        chg: "",
        chgp: "",
      },
    ]);
  }, []);
  useEffect(() => {
    setfuturesData([
      {
        "contract": "ED1",
        "symbol": "EDH21",
        "exp": "Mar21",
        "price": "9464.38",
        "prevclose": "9464.375",
        "chg": "0.00",
        "rate": "5.356%",
        "convexity": "0.0000",
        "color":"black"
      },
      {
        "contract": "ED2",
        "symbol": "EDM21",
        "exp": "Jun21",
        "price": "9475.25",
        "prevclose": "9475.25",
        "chg": "0.00",
        "rate": "0.052475%",
        "convexity": "0.0009",
        "color":"black"
      },
      {
        "contract": "ED3",
        "symbol": "EDU21",
        "exp": "Sep21",
        "price": "9493.25",
        "prevclose": "9493.25",
        "chg": "0.00",
        "rate": "0.050675%",
        "convexity": "0.0019",
        "color":"black"
      },
      {
        "contract": "ED4",
        "symbol": "EDZ21",
        "exp": "Dec21",
        "price": "9508.25",
        "prevclose": "9508.25",
        "chg": "0.00",
        "rate": "0.049175%",
        "convexity": "0.0033",
        "color":"black"
      },
      {
        "contract": "ED5",
        "symbol": "EDH22",
        "exp": "Mar22",
        "price": "9518.75",
        "prevclose": "9518.75",
        "chg": "0.00",
        "rate": "0.048125%",
        "convexity": "0.005",
        "color":"red"
      },
      {
        "contract": "ED6",
        "symbol": "EDM22",
        "exp": "Jun22",
        "price": "9524.75",
        "prevclose": "9524.75",
        "chg": "0.00",
        "rate": "0.047525%",
        "convexity": "0.007",
        "color":"red"
      },
      {
        "contract": "ED7",
        "symbol": "EDU22",
        "exp": "Sep22",
        "price": "9528.25",
        "prevclose": "9528.25",
        "chg": "0.00",
        "rate": "0.047175%",
        "convexity": "0.0094",
        "color":"red"
      },
      {
        "contract": "ED8",
        "symbol": "EDZ22",
        "exp": "Dec22",
        "price": "9528.25",
        "prevclose": "9528.25",
        "chg": "0.00",
        "rate": "0.047175%",
        "convexity": "0.012",
        "color":"red"
      },
      {
        "contract": "ED9",
        "symbol": "EDH23",
        "exp": "Mar23",
        "price": "99.45",
        "prevclose": "99.47",
        "chg": "0.00",
        "rate": "-0.0002%",
        "convexity": "0.015",
        "color":"green"
      },
      {
        "contract": "ED10",
        "symbol": "EDM23",
        "exp": "Jun23",
        "price": "99.295",
        "prevclose": "99.315",
        "chg": "0.00",
        "rate": "-0.0003%",
        "convexity": "0.0183",
        "color":"green"
      },
      {
        "contract": "ED11",
        "symbol": "EDU23",
        "exp": "Sep23",
        "price": "99.05",
        "prevclose": "99.075",
        "chg": "0.00",
        "rate": "-0.0003%",
        "convexity": "0.0219",
        "color":"green"
      },
      {
        "contract": "ED12",
        "symbol": "EDZ23",
        "exp": "Dec23",
        "price": "98.9",
        "prevclose": "98.925",
        "chg": "0.00",
        "rate": "-0.0003%",
        "convexity": "0.0257",
        "color":"green"
      },
      {
        "contract": "ED13",
        "symbol": "EDH24",
        "exp": "Mar24",
        "price": "98.75",
        "prevclose": "98.77",
        "chg": "-0.03",
        "rate": "-0.0003%",
        "convexity": "0.0298",
        "color":"blue"
      },
      {
        "contract": "ED14",
        "symbol": "EDM24",
        "exp": "Jun24",
        "price": "98.59",
        "prevclose": "98.615",
        "chg": "-0.03",
        "rate": "-0.0004%",
        "convexity": "0.0342",
        "color":"blue"
      },
      {
        "contract": "ED15",
        "symbol": "EDU24",
        "exp": "Sep24",
        "price": "98.44",
        "prevclose": "98.47",
        "chg": "-0.035",
        "rate": "-0.0004%",
        "convexity": "0.0389",
        "color":"blue"
      },
      {
        "contract": "ED16",
        "symbol": "EDZ24",
        "exp": "Dec24",
        "price": "98.31",
        "prevclose": "98.34",
        "chg": "-0.035",
        "rate": "-0.0004%",
        "convexity": "0.0438",
        "color":"blue"
      }
    ]);
  }, []);
  useEffect(() => {
    setswapratesData([
      {
        maturity: "2Y",
        code: "912828GJ3",
        coupon: "0.120%",
        maturitydate: "28-Feb-23",
        minprice: "99.969",
        yield: "4.66296%",
        pxchg: "0.0078",
        yldchg: "-0.00",
        spread: "40.24",
        swprate: "5.06536%",
        spchg: "0.00",
        srchg: "0.00",
        issuesate: "mm/dd/yy",
        firstcoupon: "mm/dd/yy",
        accrued: "0.15",
        dv01: "",
      },
      {
        maturity: "3Y",
        code: "912828GG9",
        coupon: "4.750%",
        maturitydate: "15-Feb-24",
        minprice: "100.141",
        yield: "4.58615%",
        pxchg: "0",
        yldchg: "0.00",
        spread: "41.25",
        swprate: "4.99865%",
        spchg: "0.00",
        srchg: "0.00",
        issuesate: "mm/dd/yy",
        firstcoupon: "mm/dd/yy",
        accrued: "0.15",
        dv01: "",
      },
      {
        maturity: "5Y",
        code: "912828GK0",
        coupon: "4.625%",
        maturitydate: "29-Feb-26",
        minprice: "100.11125",
        yield: "4.54567%",
        pxchg: "0",
        yldchg: "0.00",
        spread: "47.00",
        swprate: "5.01567%",
        spchg: "0.00",
        srchg: "0.00",
        issuesate: "mm/dd/yy",
        firstcoupon: "mm/dd/yy",
        accrued: "0.15",
        dv01: "",
      },
      {
        maturity: "10Y",
        code: "912828GH7",
        coupon: "4.625%",
        maturitydate: "15-Feb-31",
        minprice: "100.088",
        yield: "4.59001%",
        pxchg: "0",
        yldchg: "-0.00",
        spread: "54.25",
        swprate: "5.13251%",
        spchg: "0.00",
        srchg: "0.00",
        issuesate: "mm/dd/yy",
        firstcoupon: "mm/dd/yy",
        accrued: "0.15",
        dv01: "",
      },
      {
        maturity: "30Y",
        code: "912810PT9",
        coupon: "4.750%",
        maturitydate: "15-Feb-51",
        minprice: "100.133",
        yield: "4.72369%",
        pxchg: "0",
        yldchg: "-0.00",
        spread: "56.35",
        swprate: "5.28719%",
        spchg: "0.00",
        srchg: "0.00",
        issuesate: "mm/dd/yy",
        firstcoupon: "mm/dd/yy",
        accrued: "0.15",
        dv01: "",
      },
    ]);
  }, []);
  useEffect(() => {
    setequityData([
      {
        eqindex: "SPX",
        price: "3962.71",
        prevclose: "3968.94",
        high: "",
        low: "",
        chg: "-6.23",
        chgp: "-0.16%",
      },
      {
        eqindex: "RUT",
        price: "2319.52",
        prevclose: "2360.17",
        high: "",
        low: "",
        chg: "-40.65",
        chgp: "-1.72%",
      },
      {
        eqindex: "DJI",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "IXIC",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "SPY",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "VIX",
        price: "20.54",
        prevclose: "19.79",
        high: "",
        low: "",
        chg: "0.75",
        chgp: "3.79%",
      },
      {
        eqindex: "N225",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "FTSE",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "STOXX50E",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
      {
        eqindex: "FCHI",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },

      {
        eqindex: "GDAXI",
        price: "",
        prevclose: "",
        high: "",
        low: "",
        chg: "",
        chgp: "",
      },
    ]);
  }, []);

  useEffect(() => {
    setfxData([
      {
        ccypair: "DXY",
        price: "91.88",
        prevclose: "91.87",
        chg: "0.02",
        chgp: "0.02%",
      },
      {
        ccypair: "USDEUR",
        price: "0.8407",
        prevclose: "0.84",
        chg: "0.0006",
        chgp: "0.08% ",
      },
      {
        ccypair: "USDGBP",
        price: "0.7196",
        prevclose: "0.7199",
        chg: "-0.0002",
        chgp: "-0.02%",
      },
      {
        ccypair: "USDJPY",
        price: "109.19",
        prevclose: "109",
        chg: "0.18",
        chgp: "0.17%",
      },
      {
        ccypair: "USDCHF",
        price: "0.9275",
        prevclose: "0.9246",
        chg: "0.0029",
        chgp: "0.31%",
      },
      {
        ccypair: "USDAUD",
        price: "1.2957",
        prevclose: "1.291",
        chg: "0.0043",
       chgp: "0.04%",
      },
      {
        ccypair: "USDCAD",
        price: "1.2475",
        prevclose: "1.2446",
        chg: "0.0029",
       chgp: "0.25%",
      },
      {
        ccypair: "USDSGD",
        price: "1.3475",
        prevclose: "1.3454",
        chg: "0.002",
       chgp: "0.15%",
      },
      {
        ccypair: "EURGBP",
        price: "0.8559",
        prevclose: "0.8572",
        chg: "-0.0013",
       chgp: "-0.15%",
      },
      {
        ccypair: "EURCHF",
        price: "1.1037",
        prevclose: "1.1007",
        chg: "0.003",
       chgp: "0.26%",
      },
      {
        ccypair: "EURJPY",
        price: "129.89",
        prevclose: "129.76",
        chg: "0.14",
       chgp: "0.11%",
      },
      {
        ccypair: "AUDJPY",
        price: "84.3",
        prevclose: "84.43",
        chg: "-0.15",
       chgp: "-0.18%",
      },
      {
        ccypair: "USDBRL",
        price: "5.6237",
        prevclose: "5.6256",
        chg: "-0.0023",
       chgp: "-0.04%",
      },
      {
        ccypair: "USDMXN",
        price: "20.7069",
        prevclose: "20.621",
        chg: "0.093",
       chgp: "0.45%",
      },
      {
        ccypair: "EURMXN",
        price: "24.636",
        prevclose: "24.5462",
        chg: "0.0893",
       chgp: "0.36%",
      },
    ]);
  }, []);
  useEffect(() => {
    setcommoditiesData([
      {
        name: "WTI",
        price: "4.06",
        prevclose: "4.06",
        chg: "-0.21",
        chgp: "-4.92%",
      },
      {
        name: "BRT",
        price: "19.77",
        prevclose: "19.77",
        chg: "-0.97",
        chgp: "-4.68%",
      },
      {
        name: "CRUDE",
        price: "64.22",
        prevclose: "64.8",
        chg: "-0.58",
        chgp: "-0.90%",
      },
      {
        name: "GOLD",
        price: "1727.2",
        prevclose: "1730.9",
        chg: "-4.3",
        chgp: "-0.25%",
      },
      {
        name: "NG",
        price: "9.25",
        prevclose: "9.02",
        chg: "0.23",
        chgp: "2.55%",
      },
      {
        name: "Corn",
        price: "554",
        prevclose: "554.25",
        chg: "-0.25",
        chgp: "-0.05%",
      },
      {
        name: "Wheat",
        price: "641",
        prevclose: "647",
        chg: "-6",
        chgp: "-0.93%",
      },
    ]);
  }, []);

  function negative(number) {
    return !Object.is(Math.abs(number), +number);
  }
  return (
    <>
      <div className="container-fuild">
        <div className="row m-0 p-0 mt-4 mb-4">
          <div className="col-sm-12 text-right mb-3 pr-5">
            <DatePicker
              className="date-custom"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              popperPlacement="top-start'"
            />
          </div>
          <div className="col-sm-5 pl-2 pr-2">
            <Card>
              <Card.Header>CASH DEPOSITS</Card.Header>
              <div className="row m-0 p-0">
                <div className="col-sm-12 p-0 casedeposits-table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Rate</th>
                        <th>Basis</th>
                        <th>Prev Close</th>
                        <th>Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositsData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.startdate}</td>
                          <td>{data.enddate}</td>
                          <td>{data.rate}</td>
                          <td>{data.basis}</td>
                          <td>{data.prevclose}</td>
                          <td>{data.chg}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width="30%">Name</th>
                        <th width="20%">Rate</th>
                        <th width="20%">Prev Close</th>
                        <th width="15%">Chg</th>
                        <th width="15%">% Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {depositstwoData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.name}</td>
                          <td>{data.rate}</td>
                          <td>{data.prevclose}</td>
                          <td>{data.chg}</td>
                          <td>{data.chgp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
            <Card className="mt-2">
              <Card.Header>FUTURES</Card.Header>
              <div className="row m-0 p-0">
                <div className="col-sm-12 p-0 futures-table">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width="100px">Contract</th>
                        <th width="100px">Symbol</th>
                        <th width="100px">Exp</th>
                        <th width="100px">Price</th>
                        <th width="300px">Prev Close</th>
                        <th width="100px">Chg</th>
                        <th width="100px">Rate</th>
                        <th width="100px">Convexity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {futuresData.map((data, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.contract}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.symbol}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.exp}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.price}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.prevclose}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.chg}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.rate}
                          </td>
                          <td
                            style={{
                              color: data.color,
                            }}
                          >
                            {data.convexity}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-sm-7 pl-2 pr-2">
            <Card>
              <Card.Header>TREASURIES AND SWAP RATES</Card.Header>
              <div className="row m-0 p-0">
                <div className="col-sm-12 p-0">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Maturity</th>
                        <th>Code</th>
                        <th>Coupon</th>
                        <th>Maturity</th>
                        <th>Min Price</th>
                        <th>Yield</th>
                        <th>Px Chg</th>
                        <th>Yld Chg</th>
                        <th>Spread</th>
                        <th>Swp Rate</th>
                        <th>Sp Chg</th>
                        <th>SR Chg</th>
                        {/* <th>Issue Date</th>
                        <th>First Coupon</th>
                        <th>Accrued</th>
                        <th>Dv01</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {swapratesData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.maturity}</td>
                          <td>{data.code}</td>
                          <td>{data.coupon}</td>
                          <td>{data.maturitydate}</td>
                          <td>{data.minprice}</td>
                          <td>{data.yield}</td>
                          <td>{data.pxchg}</td>
                          <td
                            style={{
                              color: negative(data.yldchg) ? "#E04C38" : null,
                            }}
                          >
                            {data.yldchg}
                          </td>
                          <td>{data.spread}</td>
                          <td>{data.swprate}</td>
                          <td>{data.spchg}</td>
                          <td>{data.srchg}</td>
                          {/* <td>{data.issuesate}</td>
                          <td>{data.firstcoupon}</td>
                          <td>{data.accrued}</td>
                          <td>{data.dv01}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>

            <div className="row m-0 p-0 mt-2">
              <div className="col-sm-7 pl-0 pr-2">
                <Card>
                  <Card.Header>EQUITIES</Card.Header>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>INDEX</th>
                        <th>Price</th>
                        <th>Prev Close</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Chg</th>
                        <th>% Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {equityData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.eqindex}</td>
                          <td>{data.price}</td>
                          <td>{data.prevclose}</td>
                          <td>{data.high}</td>
                          <td>{data.low}</td>
                          <td>{data.chg}</td>
                          <td>{data.chgp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Symbol</th>
                        <th>Price</th>
                        <th>Prev Close</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>Chg</th>
                        <th>% Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.value.symbol}</td>
                          <td>{data.value.latestPrice}</td>
                          <td>{data.value.previousClose}</td>
                          <td>{data.value.high}</td>
                          <td>{data.value.low}</td>
                          <td>{data.value.change}</td>
                          <td>{(data.value.changePercent * 100).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </div>
              <div className="col-sm-5 pl-0 pr-0">
                <Card>
                  <Card.Header>FX</Card.Header>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>CCY PAIR</th>
                        <th>Price</th>
                        <th>Prev Close</th>
                        <th>Chg</th>
                        <th>% Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fxData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.ccypair}</td>
                          <td>{data.price}</td>
                          <td>{data.prevclose}</td>
                          <td>{data.chg}</td>
                          <td>{data.chgp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
                <Card className="mt-2 table-height">
                  <Card.Header>COMMODITIES</Card.Header>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Price</th>
                        <th>Prev Close</th>
                        <th>Chg</th>
                        <th>% Chg</th>
                      </tr>
                    </thead>
                    <tbody>
                      {commoditiesData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.name}</td>
                          <td>{data.price}</td>
                          <td>{data.prevclose}</td>
                          <td>{data.chg}</td>
                          <td>{data.chgp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </div>
          </div>
        </div>

        {/* <iframe width="100%" height="820vh" scrolling="no" frameBorder="0" src="https://airwealth-backend.digitalairtech.net/IndictiveMarket/Index" ></iframe>  */}
      </div>
    </>
  );
};

export default IndictiveMarket;
