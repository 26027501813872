import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <>
      <Navbar className="px-5 customNav customNav-bg" expand="lg">
        <Navbar.Brand href="#home">
          <div className="logo"></div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <ul className="navbar-nav ml-auto customUl customUl-color">
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/" exact>
                Home
              </NavLink>
            </li>
            <li className="nav-item px-2">
              {/* <NavLink className="nav-link font-14" to="/aboutas">
                About us
              </NavLink> */}
              <a className="nav-link text-white" href="#">
                About us
              </a>
            </li>
            <li className="nav-item px-2">
              {/* <NavLink className="nav-link font-14" to="/company">
              Company
              </NavLink> */}
              <a className="nav-link text-white" href="#">
                Company
              </a>
            </li>
            <li className="nav-item px-2">
              {/* <NavLink className="nav-link font-14" to="/solution">
              Solution
              </NavLink> */}
              <a className="nav-link text-white" href="#">
                Solution
              </a>
            </li>
            <li className="nav-item px-2">
              {/* <NavLink className="nav-link font-14" to="/support">
                Support
              </NavLink> */}
              <a className="nav-link text-white" href="#">
                Support
              </a>
            </li>
            <li className="nav-item px-2">
              {/* <NavLink className="nav-link font-14" to="/contact">
              Contact
              </NavLink> */}
              <a className="nav-link text-white" href="#">
                Contact
              </a>
            </li>
            <li className="nav-item px-2">
              <a className="nav-link text-white" href="#">
                <i className="fas fa-search"></i>
              </a>
            </li>
            <li className="nav-item px-2">
              <a className="nav-link text-white" href="#">
                <i className="fas fa-bell"></i>
              </a>
            </li>
            <li className="nav-item px-2">
              <a className="nav-link text-white" href="#">
                <i className="fas fa-user"></i>
              </a>
            </li>
            <li className="nav-item px-2">
              <NavLink className="btn text-white navbtn px-4" to="/login">
                Login
              </NavLink>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
