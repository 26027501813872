import axios from 'axios'
import AuthService from '../services/auth'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const API_URL = process.env.REACT_APP_API_URL + '/api/'

class HttpService {
  get (url, params = {}) {
    return call('GET', url, params)
  }

  post (url, params, payload = {}) {
    return call('POST', url, params, payload)
  }

  delete (url, params = {}) {
    return call('DELETE', url, params)
  }
  put (url, params = {}, payload = {}) {
    return call('PUT', url, params, payload)
  }
}

function call (method, URL, params, payload = {}) {
  const opts = {
    method,
    url: API_URL + URL
  }

  if (params) opts.params = params
  if (payload) opts.data = payload
  return axios(opts)
}

axios.interceptors.request.use(
  function (config) {
    document.body.classList.add('loading-indicator')
    const token = AuthService.getToken()
    if (token) config.headers.token = token
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  res => {
    document.body.classList.remove('loading-indicator')
    return res
  },
  err => {
    console.log(err)
    console.log(err.response.data.error)
    document.body.classList.remove('loading-indicator')
    toast.error(err.response.data.error)
  }
)

export default new HttpService()
