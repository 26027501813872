import { constants } from '../store/types'

const userJson = localStorage.getItem('user')
let currentUser = userJson !== null ? JSON.parse(userJson) : {}

const initialState = currentUser ? { loggedIn: true, currentUser } : {}

export function authReducer (state = initialState, action) {
  switch (action.type) {
    case constants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      }
    case constants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      }
    case constants.LOGIN_FAILURE:
      return {}
    case constants.LOGOUT:
      return {}
    default:
      return state
  }
}
