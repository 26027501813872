import React from "react";
import "./Trade.scss";
const Trade = () => {
  document.body.style.background = '#fff'
  return (
    <>

      <div className="container-fuild ">
      <iframe width="100%" height="750hv" scrolling="no" frameBorder="0" src="http://dairfinance-demo.digitalairtech.net/Dashboard/Index" ></iframe> 
      </div>
    </>
  );
};

export default Trade;