import React from "react";
import "./Home.scss";
import Icon1 from "../../assets/images/as1.svg";
import Icon2 from "../../assets/images/as2.svg";
import Icon3 from "../../assets/images/as3.svg";
import Icon4 from "../../assets/images/as4.svg";
import Icon5 from "../../assets/images/icon-5.jpg";
import AirWealthOp1 from "../../assets/images/FooterAirWealthOp1.png";
import { Carousel } from "react-bootstrap";

const Home = () => {
  document.body.style.background = "#fff";
  return (
    <>
      <Carousel className="custCarousel">
        <Carousel.Item>
          <div className="banner_1"></div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="banner_1"></div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="banner_1"></div>
        </Carousel.Item>
      </Carousel>
      <div className="d-flex align-items-center customBanner">
        <div className="mx-auto customBanner-div">
          <h2 className="text-uppercase text-white custCarousel-h mb-0">
          Track your<span className="custCarousel-span"> investments</span>
          </h2>
          <h4 className="text-uppercase text-white custCarousel-h4">
            in just<span className="custCarousel-span2"> one click</span>
          </h4>
          {/* <p className="text-uppercase text-white custCarousel-p">
            lorem ipsum is a simple dummy text for printing
          </p> */}
          <div className="cust-btn btn-register mt-2">
            <button
              type="button"
              className="btn text-uppercase custCarousel-btn px-4"
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
      <div className="container cust-Container">
        <div className="row text-center">
          <div className="col-md col-lg">
            <img
              src={Icon1}
              className="img-fluid imgEffectOne"
              alt="Responsive image"
            />
            <h3 className="text-uppercase img-hr imgEffectOne mt-3">Hyper Techology</h3>
            <p className="img-p imgEffectOne">
            Fully integrated platform allowing for streamlined, transparent, and direct communication, front-to-back, across systems and functions.            </p>
          </div>
          <div className="col-md col-lg">
            <img
              src={Icon2}
              className="img-fluid imgEffectOne"
              alt="Responsive image"
            />
            <h3 className="text-uppercase img-hr imgEffectOne  mt-3">Cloud Platform</h3>
            <p className="img-p imgEffectOne">
              Infinite computing power and unlimited data storage on your own private cloud resulting in reduced operational costs and increased speed, latency, and backup capabilities            </p>
          </div>
          <div className="col-md col-lg">
            <img
              src={Icon3}
              className="img-fluid imgEffectThree"
              alt="Responsive image"
            />
            <h3 className="text-uppercase img-hr imgEffectThree  mt-3">
              AI-based Analytics
            </h3>
            <p className="img-p imgEffectThree">
              Powerful AI and analytics engine to optimize trading and hedging strategies
            </p>
          </div>
          <div className="col-md col-lg">
            <img
              src={Icon4}
              className="img-fluid imgEffectTwo"
              alt="Responsive image"
            />
            <h3 className="text-uppercase img-hr imgEffectTwo  mt-3">Comprehensive Finance</h3>
            <p className="img-p imgEffectTwo">
            Trading, portfolio valuation, risk management, financial reporting, compliance, and operational functions all performed seamlessly on one integrated platform
            </p>
          </div>
          <div className="col-md col-lg">
            <img
              src={Icon2}
              className="img-fluid imgEffectTwo"
              alt="Responsive image"
            />
            <h3 className="text-uppercase img-hr imgEffectTwo mt-3">Customized Scalable Architecture</h3>
            <p className="img-p imgEffectTwo">
              Scalable and flexible - as your business and portfolio grow, Digital Air’s platform grows with you, customized for your needs and goals
            </p>
          </div>
        </div>

        <div className="row mt-5 pt-5 no-gutters">
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectLeft">
            <h3 className="text-uppercase customHeader">
              welcome to <span className="customSpan">AirWealth</span>
            </h3>
            <p className="customPara">
              The solution for today’s portfolio manager.  Front-to-back cloud-based multi-asset trading and processing 
              platform​ that covers trade execution, pre-trade and post-trade service and analytics straight from trade 
              inception down to balance sheet reporting. <br />  <br /> 
              Dashboards provide portfolio statistics at a glance.  Real time and end-of-day market data for intra-day and 
              close-of-business portfolio valuation.  Risk management, financial analysis and reporting, and operational processes 
              all executed seamlessly on one platform, speaking the same langugage - reducing operational costs and increasing productivity. <br /><br />
              A unified platform increases data transparency for better governance and stronger, enhaned controls.
            </p>
          </div>
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectRight">
            <div
              className="Parent_img d-flex align-items-center"
              id="ParentImg"
            >
              <div className="Child_img" id="ChildImg"></div>
            </div>
          </div>
        </div>
        <div className="row mt-5 pt-5 no-gutters">
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectLeftTwo">
            <div className="Parent_img-2" id="ParentImg-2">
              <div className="Child_img-2" id="ChildImg-2"></div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectRightTwo">
            <h3 className="text-uppercase customHeader">
              easy way to <span className="customSpan-2">Grow</span>
            </h3>
            <p className="customPara">
              Nimble and flexible techology grows with your business.  AirWealth’s Trading, 
              Analytics, and Reporting suite of functions can be tailored to meet your business needs and growth strategies. <br /><br /> 
              An agile platform that not only supports the trading and management of multiple instruments and asset types 
              as your portfolio grows, but is also capable of building and growing the downstream functions and control 
              processes that underpin effective portfolio management. <br /><br />
              Scalability and flexibility are critical for successful portfolio management and  fast-paced growth.  
              AirWealth is your partner on the journey. 
            </p>
          </div>
        </div>
        {/* <div className="row my-5 py-5 no-gutters">
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectLeftThree">
            <h3 className="text-uppercase customHeader text-right">
              24x7 <span className="customSpan-3">Fast service</span>
            </h3>
            <p className="customPara">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
          <div className="col-md-6 col-sm-6 col-sm-12 col-lg-6 scrollEffectRightThree">
            <div className="Parent_img-3" id="ParentImg-3">
              <div className="Child_img-3" id="ChildImg-3"></div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="">
        <div className="container-fluid mb-5 mt-5">
          <div className="row">
            <img src={AirWealthOp1} width="100%" />
            {/* <div className="col-md-3 col-sm-3 col-lg-3">
              <div className="airwealthOp"></div>
            </div> */}
            {/*  <div className="col-md-9 col-sm-9 col-lg-9">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="bgColor-Logo"></div>
                </div>
                <div className="col-lg-6 col-md-6 d-flex align-items-center d-flex justify-content-center col-sm-6 w-100 py-4">
                  <button type="button" className="btn btn-platform">
                    <div className="media">
                      <i className="fab fa-google-play"></i>
                      <div className="media-body">
                        <h5 className="my-0 text-uppercase">andriod app on</h5>
                        <p className="py-0 mb-0 pl-1"> Google Play</p>
                      </div>
                    </div>
                  </button>
                  <button type="button" className="btn btn-platform ml-4">
                    <div className="media">
                      <i className="fab fa-apple btn-i"></i>
                      <div className="media-body pl-1">
                        <h5 className="my-0 text-uppercase">Download on the</h5>
                        <p className="py-0 mb-0 pl-1">App Store</p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
