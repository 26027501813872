import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Dashboard from "../../containers/Child/Dashboard/Dashboard";
import IndictiveMarket from "../../containers/Child/IndictiveMarket/IndictiveMarket";
import MarketData from "../../containers/Child/MarketData/MarketData";
import Blotter from "../../containers/Child/Blotter/Blotter";
import Trade from "../../containers/Child/Trade/Trade";
import Flash from "../../containers/Child/Flash/Flash";
import Allocation from "../../containers/Child/Allocation/Allocation";

const Layout = (props) => {
  const { match } = props;
  return (
    <>
      <Header />
      <div>
        <Switch>
          <Route path="/index/Dashboard" component={Dashboard} />
          <Route path="/index/IndictiveMarket" component={IndictiveMarket} />
          <Route path="/index/Marketdata" component={MarketData} />
          <Route path="/index/Blotter" component={Blotter} />
          <Route path="/index/Trade" component={Trade} />
          <Route path="/index/Flash" component={Flash} />
          <Route path="/index/Allocation" component={Allocation} />
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
