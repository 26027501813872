import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "../../containers/Home/Home";
import Login from "../../containers/Login/Login";

const Layout = (props) => {
  const { match } = props;
  return (
    <>
      <Header />
      <div>
        <Switch>
      
          <Route path="/" component={Home} />
          <Route
            exact
            path={`${match.path}home`}
            render={(props) => <Home {...props} />}
          />
         
        </Switch>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
