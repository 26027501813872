import { useEffect, useRef, useState } from "react";
import DataGrid from "react-data-grid";
import { object } from "yup";

document.body.style.background = "#efefef";

const flashobj = {
  AKAM: {
    quantity: 330000,
    totalHolding: 0.0232,
    MTD: 105.24,
    QTD: 95.94,
  },
  AAPL: {
    quantity: 430000,
    totalHolding: 0.053,
    MTD: 129.22,
    QTD: 127.79,
  },
  CAKE: {
    quantity: 440000,
    totalHolding: 0.0247,
    MTD: 35.41,
    QTD: 55.46,
  },
  ETFC: {
    quantity: 960000,
    totalHolding: 0.026,
    MTD: 0,
    QTD: 0,
  },
  ETH: {
    quantity: 710000,
    totalHolding: 0.0246,
    MTD: 1040.23,
    QTD: 1564.71,
  },
  FDS: {
    quantity: 150000,
    totalHolding: 0.0236,
    MTD: 329.74,
    QTD: 311.18,
  },
  GGG: {
    quantity: 260000,
    totalHolding: 0.0233,
    MTD: 70.42,
    QTD: 70.96,
  },
  INTU: {
    quantity: 220000,
    totalHolding: 0.0227,
    MTD: 371.11,
    QTD: 406.41,
  },
  JBLU: {
    quantity: 1440000,
    totalHolding: 0.0255,
    MTD: 14.03,
    QTD: 18.48,
  },
  LB: {
    quantity: 610000,
    totalHolding: 0.059,
    MTD: 39.6,
    QTD: 56.75,
  },
  PLT: {
    quantity: 400000,
    totalHolding: 0.0237,
    MTD: 26.29,
    QTD: 43.49,
  },
  RL: {
    quantity: 110000,
    totalHolding: 0.0227,
    MTD: 101.33,
    QTD: 120.34,
  },
  RCL: {
    quantity: 290000,
    totalHolding: 0.0267,
    MTD: 70.72,
    QTD: 91.35,
  },
  STX: {
    quantity: 320000,
    totalHolding: 0.0238,
    MTD: 60.08,
    QTD: 73.81,
  },
  SO: {
    quantity: 880000,
    totalHolding: 0.0483,
    MTD: 58.74,
    QTD: 57.78,
  },
  TJX: {
    quantity: 320000,
    totalHolding: 0.0245,
    MTD: 66.56,
    QTD: 67.25,
  },
  CASH: {
    quantity: 290000,
    totalHolding: 0.0267,
    MTD: 0,
    QTD: 0,
  },
};

function Flash() {
  const ws = useRef(null);
  const [flashData, setflashData] = useState([]);
  let feeds = [];

  const defaultColumnProperties = {
    editable: true,
    resizable: true,
    style: { whiteSpace: "unset" },
  };

  const columns = [
    { key: "symbol", name: "Ticker", width: 50 },
    { key: "companyName", name: "Security Name", width: 300 },
    { key: "newquantity", name: "Quantity" },
    { key: "totalHolding", name: "Holdings %", width: 100 },
    { key: "previousClose", name: "Prev Close", width: 100 },
    { key: "latestPrice", name: "Curr Price", width: 100 },
    { key: "preMkt", name: "Prev Mkt Value", width: 130 },
    { key: "currMkt", name: "Current Mkt Value", width: 160 },
    { key: "pandl", name: " P&L Daily" },
    { key: "pandlm", name: " P&L MTD" },
    { key: "pandlq", name: " P&L QTD" },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  useEffect(() => {
    ws.current = new WebSocket("wss://finance-demo-api.digitalairtech.net/ws");
    ws.current.onopen = () => {
      ws.current.send(JSON.stringify({ RequestType: "FlashBucket" }));
    };
    ws.current.onclose = () => console.log("ws closed");
    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    if (!ws.current) return;
    ws.current.onmessage = (e) => {
      var messages = JSON.parse(e.data);
      let objdata = messages.Data;
      let index = feeds.findIndex(
        (vendor) => vendor["symbol"] === objdata.symbol
      );
      if (index == -1) {
        if (flashobj[objdata.symbol]) {
          // objdata.quantity = flashobj[objdata.symbol].quantity.toFixed(2);
          objdata.quantity = flashobj[objdata.symbol].quantity;
          objdata.quantity = objdata.quantity.toFixed(3).slice(0,-1);
          objdata.newquantity =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.quantity);

          objdata.totalHolding =
            (flashobj[objdata.symbol].totalHolding * 100).toFixed(2) + "%";
        } else {
          objdata.quantity = 0;
          objdata.totalHolding = "0%";
        }

             //  objdata.preMkt = (objdata.quantity * objdata.previousClose).toFixed(2);
        // objdata.currMkt = (objdata.quantity * objdata.latestPrice).toFixed(2);
        // objdata.pandl = objdata.currMkt - objdata.preMkt;
        
        objdata.previousClose = objdata.previousClose.toFixed(3).slice(0,-1);
        objdata.previousClose =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.previousClose); 
        
        objdata.latestPrice = objdata.latestPrice.toFixed(3).slice(0,-1);
        objdata.latestPrice =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.latestPrice);
        
        // objdata.previousClose = objdata.previousClose.toFixed(2);
        // objdata.latestPrice = objdata.latestPrice.toFixed(2);
        objdata.preMkt = new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format((objdata.quantity * objdata.previousClose));
        
        objdata.currMkt = new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format((objdata.quantity * objdata.latestPrice));
        
        objdata.pandl = (objdata.quantity * objdata.latestPrice) - (objdata.quantity * objdata.previousClose);
        object.pandl = objdata.pandl.toFixed(3).slice(0,-1);
        objdata.pandl =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandl);

        if (flashobj[objdata.symbol]) {
          objdata.pandlm =
            (objdata.quantity * objdata.latestPrice) - flashobj[objdata.symbol].MTD * objdata.quantity;
            object.pandlm = objdata.pandlm.toFixed(3).slice(0,-1);
            objdata.pandlm =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandlm);

          objdata.pandlq =
            (objdata.quantity * objdata.latestPrice) - flashobj[objdata.symbol].QTD * objdata.quantity;
            object.pandlq = objdata.pandlq.toFixed(3).slice(0,-1);
            objdata.pandlq =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandlq);
        } else {
          objdata.pandlm = 0;
          objdata.pandlq = 0;
        }
        feeds.push(objdata);
      } else {
        if (flashobj[objdata.symbol]) {
          // objdata.quantity = flashobj[objdata.symbol].quantity.toFixed(2);
          
          objdata.quantity = flashobj[objdata.symbol].quantity;
           objdata.quantity = objdata.quantity.toFixed(3).slice(0,-1);
           objdata.newquantity =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.quantity);

          objdata.totalHolding =
            (flashobj[objdata.symbol].totalHolding * 100).toFixed(2) + "%";
        } else {
          objdata.quantity = 0;
          objdata.totalHolding = "0%";
        }

        // objdata.preMkt = (objdata.quantity * objdata.previousClose).toFixed(2);
        // objdata.currMkt = (objdata.quantity * objdata.latestPrice).toFixed(2);
        objdata.previousClose = objdata.previousClose.toFixed(3).slice(0,-1);
        objdata.previousClose =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.previousClose); 
        
        objdata.latestPrice = objdata.latestPrice.toFixed(3).slice(0,-1);
        objdata.latestPrice =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.latestPrice);

        objdata.preMkt = (objdata.quantity * objdata.previousClose);
        object.preMkt = objdata.preMkt.toFixed(3).slice(0,-1);
        objdata.preMkt =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.preMkt);

        objdata.currMkt = (objdata.quantity * objdata.latestPrice);
        object.currMkt = objdata.currMkt.toFixed(3).slice(0,-1);
        objdata.currMkt =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.currMkt);

        objdata.pandl =(objdata.quantity * objdata.latestPrice) - (objdata.quantity * objdata.previousClose);
        object.pandl = objdata.pandl.toFixed(3).slice(0,-1);
        objdata.pandl =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandl);

        if (flashobj[objdata.symbol]) {
          objdata.pandlm =
          (objdata.quantity * objdata.latestPrice)- flashobj[objdata.symbol].MTD * objdata.quantity;
          object.pandlm = objdata.pandlm.toFixed(3).slice(0,-1);
          objdata.pandlm =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandlm);
          
          objdata.pandlq =
          (objdata.quantity * objdata.latestPrice) - flashobj[objdata.symbol].QTD * objdata.quantity;
          object.pandlq = objdata.pandlq.toFixed(3).slice(0,-1);
          objdata.pandlq =  new Intl.NumberFormat('en-US',{ minimumFractionDigits : 2}).format(objdata.pandlq);
        } else {
          objdata.pandlm = 0;
          objdata.pandlq = 0;
        }
        feeds[index] = objdata;
      }

      setflashData([...feeds]);
    };
  }, []);

  return (
    <>
      <div className="container-fuild mt-4 mb-4">
        <div className="row p-0 m-0">
          <div className="col-md-12 ">
            <DataGrid
              style={{ height: window.innerHeight - 225 || 200, width: "100%" }}
              columns={columns}
              rows={flashData}
              resizable={true}
              onColumnResize={(idx, width) =>
                console.log(`Column ${idx} has been resized to ${width}`)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Flash;
