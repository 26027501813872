import HttpService from '../common/http.service'

class AuthService {
  login (email, password) {
    return HttpService.post('login', null, { email, password }).then(res => {
      if (res.data) {
        localStorage.setItem('token', res.data.token)
      }

      return res.data
    })
  }

  logout () {
    localStorage.clear()
  }

  getToken () {
    return localStorage.getItem('token')
  }
}

export default new AuthService()
