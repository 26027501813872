import React from "react";
import marketdataBG from "../../../assets/images/marketdataBG.jpg";

const MarketData = () => {
document.body.style.background = '#efefef'

  return (
    <>

      <div className="container-fuild">
      {/* <img src={marketdataBG} alt="logo" width="100%" /> */}
     <iframe  scrolling="no"  width="100%" height="1080hv" src="http://dairfinance-demo.digitalairtech.net/MarketData/Index" ></iframe> 
      </div>
    </>
  );
};

export default MarketData;
