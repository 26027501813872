import React from "react";

const Allocation = () => {
  document.body.style.background = '#fff'
  return (
    <>

      <div className="container-fuild ">
      <iframe width="100%" height="700hv" scrolling="no" frameBorder="0" src=" http://dairfinance-demo.digitalairtech.net/AsiaPortfolio/Index" ></iframe> 
      </div>
    </>
  );
};

export default Allocation;