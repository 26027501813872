import React from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Navbar className="px-5 customNav new-custombg" expand="lg">
        <Navbar.Brand href="#">
          <div className="logo logo1"></div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <ul className="navbar-nav ml-auto customUl link-color">
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Dashboard">
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/IndictiveMarket">
              Market Data
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Marketdata">
                Strategy
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Blotter">
                Blotter
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Trade">
                Trade
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Flash">
                Flash
              </NavLink>
            </li>
            <li className="nav-item px-2">
              <NavLink className="nav-link text-white" to="/index/Allocation">
                Portfolio Allocation
              </NavLink>
            </li>
            <li className="nav-item px-2">
            <a className="nav-link text-white" href="https://trading-new.bizinance.com">
            Bizio Pro
              </a>
              {/* <NavLink className="nav-link text-white" to="/index/Analysis">
              Portfolio Analysis
              </NavLink> */}
            </li>
            <li className="nav-item px-2">
            <a className="nav-link text-white" href="https://trading.bizinance.com">
            Bizinance Trading
              </a>
              {/* <NavLink className="nav-link text-white" to="/index/Strategy">
              Strategy Analysis
              </NavLink> */}
            </li>
            <li className="nav-item px-2">
              <a className="nav-link text-white" href="#">
                <i className="fas fa-search"></i>
              </a>
            </li>
            <li className="nav-item px-2">
              <a className="nav-link text-white" href="#">
                <i className="fas fa-bell"></i>
              </a>
            </li>
            <li className="nav-item px-2 btn-white">
              <Dropdown>
                <Dropdown.Toggle className="text-white" id="dropdown-basic">
                  <i className="fas fa-user"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="rounded-0 dropdown-menu-right p-1">
                  <Dropdown.Item eventKey={2} href="/login">
                    <i className="fas fa-sign-out-alt text-dark"></i> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
